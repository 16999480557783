export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    $('.mobile-menu-click').click(function() {
      if ($('.mobile-menu-text').text() == 'MENU') {
        $('.mobile-menu-text').text('CLOSE MENU');
        $('.nav-primary').addClass('flex');
        $('.nav').slideDown('100');
        $('#menu-main-menu').addClass('mobile-expand');
      }
      else {
        $('.mobile-menu-text').text('MENU');
        $('.nav-primary').removeClass('flex');
        $('#menu-main-menu').removeClass('mobile-expand');
        $('.sub-menu').slideUp('100').removeClass('expand');
        $('.mobile-icon').removeClass('submenu-open');

      }
    });
    $('.mobile-icon').click(function () {
      event.preventDefault();
      if ($(this).siblings('.sub-menu').hasClass('expand')) {
        $(this).removeClass('submenu-open');
        $(this).siblings('.sub-menu').slideUp('100').removeClass('expand');
      }
      else {
        $(this).siblings('.sub-menu').slideDown('100').addClass('expand');
        $(this).addClass('submenu-open');
      }

    });
    $('a.mobile-menu-icon').click(function () {
      event.preventDefault();
      if ($('a.mobile-menu-icon').hasClass('active')) {
        $('a.mobile-menu-icon').removeClass('active');
        $('.mobile-overlay').removeClass('active');
        $('.mobile-menu').removeClass('show-mobile-menu');
        $('.close-mobile-menu-icon').removeClass('show-mobile-close');
        $('body').removeClass('show-overlay-mobile');
      } else {
        $('a.mobile-menu-icon').addClass('active');
        $('.mobile-overlay').addClass('active');
        $('a.mobile-menu-icon').addClass('active');
        $('.mobile-menu').addClass('show-mobile-menu');
        $('.close-mobile-menu-icon').addClass('show-mobile-close');
        $('body').addClass('show-overlay-mobile');
      }
    });

    $('.close-mobile-menu-icon span').click(function () {
      $('a.mobile-menu-icon').removeClass('active');
      $('.mobile-overlay').removeClass('active');
      $('body').removeClass('show-overlay-mobile');
      $('.mobile-menu').removeClass('show-mobile-menu');
      $('.close-mobile-menu-icon').removeClass('show-mobile-close');
      $('.sub-menu').slideUp('100').removeClass('expand');
      $('.mobile-icon').removeClass('submenu-open');
    })
    $(function() {

      createSticky($('.nav-primary'));
    });
  
    function createSticky(sticky) {
      if (typeof sticky !== 'undefined') {
  
        var pos = sticky.offset().top,
            win = $(window);
  
        win.on('scroll', function() {
          win.scrollTop() > pos ? sticky.addClass('sticky-mobile-on') : sticky.removeClass('sticky-mobile-on');
        });
      }
  
    }
  
  },
};
