// import external dependencies
import 'jquery';

// Import FontAwesom 5 Free
import { config, library, dom } from '@fortawesome/fontawesome-svg-core';
import {faMapMarker, faPhoneAlt, faBars, faTrashAlt, faHistory, faTimes, faChevronDown, faShoppingCart} from '@fortawesome/free-solid-svg-icons';

config.searchPseudoElements = true;

library.add(faMapMarker, faPhoneAlt, faBars, faTrashAlt, faHistory, faTimes, faChevronDown, faShoppingCart);

dom.watch();

// Import everything from autoload


// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
